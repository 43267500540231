<template>
  <b-row>
    <b-col>
      <b-form-input v-model="selectedPeriodVal" type="number" />
    </b-col>
    <b-col>
      <b-form-select v-model="selectedPeriod" :options="options" />
    </b-col>
  </b-row>
</template>

<script>
import * as _ from 'lodash';

export default {
  props: ['value'],
  data () {
    return {
      selectedPeriod: 'months',
      selectedPeriodVal: '1',
      options: [
        { value: 'days', text: 'Day(s)' },
        { value: 'months', text: 'Month(s)' },
        { value: 'years', text: 'Year(s)' },
      ],
    };
  },
  watch: {
    value (val) {
      this.checkValue(val);
    },
    selectedPeriod () {
      this.emit();
    },
    selectedPeriodVal () {
      this.emit();
    },
  },
  mounted () {
    this.checkValue(this.value);
    this.emit();
  },
  methods: {
    emit () {
      const value = {};
      if (this.selectedPeriod) {
        value[this.selectedPeriod] = this.selectedPeriodVal;
      }
      this.$emit('input', value);
    },
    checkValue (val) {
      let period = 'months';
      let value = '1';
      if (_.has(val, 'days')) {
        period = 'days';
        value = val.days;
      } else if (_.has(val, 'months')) {
        period = 'months';
        value = val.months;
      } else if (_.has(val, 'years')) {
        period = 'years';
        value = val.years;
      }
      if (period !== this.selectedPeriod || value !== this.selectedPeriodVal) {
        this.selectedPeriod = period;
        this.selectedPeriodVal = value;
      }
    },
  },
};
</script>
