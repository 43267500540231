<template>
  <span>
    <a v-if="modules && !!modules['services']" href="#" @click.prevent="openServices()"><fa-icon icon="couch" />Included Services</a>
    <b-modal ref="servicesmodal" size="lg" no-enforce-focus title="Included services">
      <b-row>
        <b-col cols="8">
          <b-button-toolbar class="float-right" key-nav>
            <b-button-group>
              <b-button variant="success" @click="newServiceItem">
                <fa-icon icon="plus" /> New
              </b-button>
            </b-button-group>
          </b-button-toolbar>

          <b-table
            empty-html="No items to show" :fields="servicesFields" :items="services" small responsive striped
            hover show-empty
          >
            <template v-slot:cell(serviceId)="{ item }">
              {{ item.service.name }}
            </template>
            <template v-slot:cell(type)="{ item }">
              {{ item.service.type }}
            </template>
            <template v-slot:cell(taxId)="{ item }">
              {{ item.tax.name }} ({{ item.tax.percentage }}%)
            </template>
            <template v-slot:cell(actions)="{ item }">
              <a href="#" class="text-primary" title="Edit" @click.prevent="editServiceItem(item)"><fa-icon icon="edit" /></a>
              <a href="#" class="text-danger" title="Delete" @click.prevent="removeServiceItem(item)"><fa-icon icon="trash" /></a>
            </template>
          </b-table>
        </b-col>
        <b-col v-if="newEditService">
          <form @submit.prevent="saveService">
            <b-form-group label="Service" label-for="serviceId">
              <blueprint-search-input
                id="serviceId"
                :key="service.serviceId"
                v-model="service.serviceId"
                placeholder="Type to search"
                api-route-path="inventory/services"
                :where="{type: { $in: ['MONTH_SUBSCRIPTION', 'MONTH_SUBSCRIPTION_PER_M2']}}"
              />
            </b-form-group>
            <b-form-group label="Price" label-for="price" description="Without taxes">
              <b-form-input id="price" v-model="service.price" step="0.01" type="number" placeholder="Price" />
            </b-form-group>
            <b-form-group label="Tax" label-for="taxId">
              <blueprint-search-input
                id="taxId"
                v-model="service.taxId"
                placeholder="Type to search"
                api-route-path="inventory/taxes"
                :state="state('taxId')"
              />
            </b-form-group>
            <b-form-group v-if="modules && !!modules['invoices']" label="Product code">
              <b-form-input id="productCode" v-model="service.productCode" :state="state('productCode')" type="text" placeholder="Product Code" />
            </b-form-group>
            <div class="text-center">
              <b-button variant="success" type="submit">Save</b-button>
            </div>
          </form>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">Close</b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCouch } from '@fortawesome/free-solid-svg-icons';

library.add(faCouch);

export default {
  props: ['productId'],
  data () {
    return {
      errors: [],
      service: {},
      newEditService: false,
      services: [],
      servicesFields: [
        {
          key: 'serviceId',
          label: 'Service',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'price',
        },
        {
          key: 'taxId',
          label: 'Tax',
        },
        {
          key: 'actions',
          class: 'actions',
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    openServices () {
      this.newEditService = false;
      this.getServices()
        .then(() => this.$refs.servicesmodal.show())
        .catch(this.err);
    },

    getServices () {
      return this.$http.get(`inventory/products/${this.productId}/included-services`)
        .then((res) => {
          this.services = res.data;
          this.errors = {};
        })
        .catch(this.err);
    },
    saveService () {
      const { service } = this;
      const url = `inventory/products/${this.productId}/included-services`;
      let promise;
      if (service.id) {
        promise = this.$http.patch(`${url}/${service.id}`, service);
      } else {
        promise = this.$http.post(url, service);
      }
      promise
        .then(() => {
          this.service = {};
          this.newEditService = false;
        })
        .then(() => this.getServices())
        .catch(this.err);
    },
    newServiceItem () {
      this.service = { service: { } };
      this.newEditService = true;
    },
    editServiceItem (item) {
      const { id } = item;
      const url = `inventory/products/${this.productId}/included-services/${id}`;
      this.$http.get(url)
        .then((res) => {
          this.service = res.data;
          this.newEditService = true;
        })
        .catch(this.err);
    },
    removeServiceItem (item) {
      const { id } = item;
      const url = `inventory/products/${this.productId}/included-services/${id}`;
      this.$http.delete(url)
        .then(() => this.getServices())
        .catch(this.err);
    },
  },
};
</script>
