<template>
  <div class="row">
    <div class="col">
      <b-card title="Products">
        <blueprint-data api-route-path="inventory/products" :new-default-values="newDefaultValues" :fields="fields">
          <template v-slot:propertyId="{ item }">
            {{ item.property.name }}
          </template>
          <template v-slot:typeId="{ item }">
            {{ item.type.name }}
          </template>
          <template v-slot:unitcount="{ item }">
            {{ item.type.unitCount }}
          </template>
          <template v-slot:price="{ item }">
            {{ item.price?parseFloat(item.price).toFixed(2):0 }}
          </template>
          <template v-slot:taxId="{ item }">
            {{ item.tax.name }} ({{ item.tax.percentage }}%)
          </template>
          <template v-slot:minimumPeriod="{ item }">
            <span v-if="item.minimumPeriod && item.minimumPeriod.days">
              {{ item.minimumPeriod.days }} day(s)
            </span>
            <span v-else-if="item.minimumPeriod && item.minimumPeriod.months">
              {{ item.minimumPeriod.months }} months(s)
            </span>
            <span v-else-if="item.minimumPeriod && item.minimumPeriod.years">
              {{ item.minimumPeriod.years }} years(s)
            </span>
          </template>

          <template v-slot:availability="{ item }">
            <b-badge v-if="item.availability === 'AVAILABLE'" variant="success">
              Available
            </b-badge>
            <b-badge v-if="item.availability !== 'AVAILABLE'" variant="danger">
              Not available
            </b-badge>
          </template>
          <template v-slot:actions="{ item }">
            <ProductServicesModal :product-id="item.id" />
            <ProductIncludedServicesModal :product-id="item.id" />
            <ProductUtilityMeterModal :product-id="item.id" />
          </template>

          <template v-slot:default="{ state, item }">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Minimum rental period">
              <period-selector v-model="item.minimumPeriod" :state="state('minimumPeriod')" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Property" label-for="propertyId">
              <blueprint-search-input
                id="propertyId"
                v-model="item.propertyId"
                placeholder="Type to search"
                api-route-path="inventory/properties"
                :state="state('propertyId')"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Type" label-for="typeId">
              <blueprint-search-input
                id="typeId"
                v-model="item.typeId"
                placeholder="Type to search"
                api-route-path="inventory/types"
                :state="state('typeId')"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Price" label-for="price" description="Without taxes">
              <b-row>
                <b-col>
                  <b-form-input id="price" v-model="item.price" step="0.01" :state="state('price')" type="number" placeholder="Price" />
                </b-col><b-col>
                  <b-form-select v-model="item.priceMethod" :state="state('priceMethod')">
                    <option value="DAY">
                      Per-day
                    </option>
                    <option value="MONTH">
                      Per-month
                    </option>
                    <option value="MONTH_PER_M2">
                      Per-month per m2
                    </option>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Tax" label-for="taxId">
              <blueprint-search-input
                id="taxId"
                v-model="item.taxId"
                placeholder="Type to search"
                api-route-path="inventory/taxes"
                :state="state('taxId')"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Availability for rent" label-for="availability">
              <b-form-select id="availability" v-model="item.availability" :state="state('availability')" :options="availabilities" />
            </b-form-group>
            <b-form-group v-if="modules && !!modules['invoices']" label-cols-sm="4" label-cols-lg="3" label="Product code" label-for="product-code-selection-wrap">
              <b-form-input id="productCode" v-model="item.productCode" :state="state('productCode')" type="text" placeholder="Product Code" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faShoppingBasket, faCouch, faEdit, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import PeriodSelector from '../../../components/period-selector.vue';
import ProductServicesModal from '../../../service-modules/services/components/product-services-link.vue';
import ProductIncludedServicesModal from '../../../service-modules/services/components/product-included-services-link.vue';
import ProductUtilityMeterModal from '../../../service-modules/meter_data/components/product-meters-link.vue';

library.add(faShoppingBasket, faCouch, faEdit, faTrash);

export default {
  components: {
    PeriodSelector,
    ProductServicesModal,
    ProductUtilityMeterModal,
    ProductIncludedServicesModal
  },
  data () {
    return {
      services: [],
      productId: '',
      errors: {},
      newDefaultValues: {
        availability: 'NOT_AVAILABLE',
        priceMethod: 'MONTH',
        minimumPeriod: { months: '1' },
        price: '0',
      },
      availabilities: [
        { value: 'AVAILABLE', text: 'Available' },
        { value: 'NOT_AVAILABLE', text: 'Not Available' },
      ],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'propertyId',
          label: 'Property',
          sortable: true,
          slot: true,
        },
        {
          key: 'typeId',
          label: 'Unit type',
          sortable: true,
          slot: true,
        },
        {
          key: 'unitcount',
          label: 'Unit count',
          sortable: false,
          slot: true,
        },
        {
          key: 'availability',
          label: 'Availability for rent',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          sortable: true,
          slot: true,
        },
        {
          key: 'taxId',
          label: 'Tax',
          sortable: true,
          slot: true,
        },
        {
          key: 'minimumPeriod',
          label: 'Minimum period',
          slot: true,
          sortable: true,
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    }
  }
};
</script>
